import React, { useEffect } from "react";
import { LayoutAboutUs } from "../components/layouts/layout-about-us";
import { Container, Row, Col } from "react-bootstrap";
import eadashboard from "../images/eadashboard.png";
import sitefinity from "../images/logo/sitefinity.png";
import sitecore from "../images/logo/sitecore.png";
import sharepoint from "../images/logo/sharepoint.png";
import umbraco from "../images/logo/umbraco.png";
import drupal from "../images/logo/drupal.png";
import azure from "../images/logo/azure.png";
import angular from "../images/logo/angular.png";
import dotnet from "../images/logo/dotnet.png";
import instagramIcon from "../images/instagram-icon.svg";
import facebookIcon from "../images/facebook-icon.svg";
import linkedinIcon from "../images/linkedin-icon.svg";
import ScrollIndicator from "../components/shared/scroll-indicator";
import SEO from "../components/shared/seo";
import { LogoText } from "../components/shared/logo";
import {
  IoIosTrendingUp,
  IoMdLaptop,
  IoIosCode,
  IoIosCloud,
  IoIosDoneAll,
} from "react-icons/io";

function AboutUs() {
  return (
    <>
      <SEO title="About Us" />
      <LayoutAboutUs>
        <div
          id="about-us-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <section className="introduction">
            <Container>
              <Row>
                <Col md={12} lg={8}>
                  <h6>Diviv Group</h6>
                  <h2>About Us</h2>
                  <p>
                    At Diviv Group, we care deeply about your business outcomes.
                    That’s why we deliver creative technology solutions to power
                    your business and overcome the challenges of the modern
                    workplace.
                  </p>
                  <p>
                    Operating at the intersection of technology and creativity,
                    we aim to deliver meaningful outcomes that make an impact on
                    your business.
                  </p>
                  <p>
                    Our team is made up of technical experts who integrate
                    complex systems, to facilitate simpler and faster business
                    processes for our clients. We deliver business insights, so
                    that leadership teams that regularly rely on our work can
                    make informed decisions on empirical evidence.
                  </p>
                </Col>

                <Col md={12} lg={4} className="social-media">
                  <div className="social-media__links">
                    <div className="social-media__links__title">Follow us</div>

                    <div className="social-media__links__account">
                      <a
                        href="https://www.instagram.com/divivgroup/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <div className="icon">
                          {" "}
                          <img
                            src={instagramIcon}
                            style={{ width: "15px", height: "15px" }}
                            alt="Follow Diviv on Instagram"
                          />
                        </div>
                        Instagram
                      </a>
                    </div>

                    <div className="social-media__links__account">
                      <a
                        href="https://www.facebook.com/divivgroup/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <div className="icon">
                          {" "}
                          <img
                            src={facebookIcon}
                            className="facebook"
                            alt="Follow Diviv on Facebook"
                          />
                        </div>
                        Facebook{" "}
                      </a>
                    </div>

                    <div className="social-media__links__account">
                      <a
                        href="https://www.linkedin.com/company/17890171"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <div className="icon">
                          {" "}
                          <img
                            src={linkedinIcon}
                            style={{ width: "15px" }}
                            alt="Follow Diviv on Linkedin"
                          />
                        </div>
                        Linkedin{" "}
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* <ScrollIndicator
            onClick={() => {
              document.getElementById("about-us-content").scrollIntoView({
                behavior: "smooth",
              });
            }}
            visible={true}
            fill={`#d8d8dd`}
          /> */}

          <section className="services-section">
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="diviv-banner mb-5">
                    <div className="diviv-banner__logo">
                      <LogoText />
                    </div>
                    <p className="diviv-banner__text">
                      At Diviv, we do things differently and genuinely care
                      about our people and our clients. We also believe that
                      transparency is a vital ingredient in building
                      long-standing mutually beneficial relationships.
                    </p>
                  </div>
                </Col>
                <Col sm={12} lg={6}>
                  <div className="about-us-section__icon">
                    <IoIosCloud
                      size={50}
                      className="list--services__item__icon"
                    />
                  </div>
                  <h5 className="subtitle">Business System Integration</h5>
                  <p>
                    Our business system integration solutions help you transform
                    processes and technology investments into innovative
                    solutions.
                  </p>
                </Col>
                <Col sm={12} lg={6}>
                  <div className="about-us-section__icon">
                    <IoIosCode
                      size={50}
                      className="list--services__item__icon"
                    />
                  </div>
                  <h5 className="subtitle">Application Development</h5>
                  <p>
                    We help you build solutions that give you a competitive edge
                    in today’s digitally driven world.
                  </p>
                </Col>
                <Col sm={12} lg={6}>
                  <div className="about-us-section__icon">
                    <IoIosDoneAll
                      size={50}
                      className="list--services__item__icon"
                    />
                  </div>
                  <h5 className="subtitle">
                    Technical Expertise &amp; Consultancy
                  </h5>
                  <p>
                    Our skilled team of tech professionals can provide the
                    strategic advice your business needs across the full
                    spectrum of digital enterprise solutions.
                  </p>
                </Col>
                <Col sm={12} lg={6}>
                  <div className="about-us-section__icon">
                    <IoIosTrendingUp
                      size={50}
                      className="list--services__item__icon"
                    />
                  </div>
                  <h5 className="subtitle">Digital Business Strategy</h5>
                  <p>
                    Combining the expertise of leading developers, business
                    architects, and digital marketing specialists, we deliver a
                    digital business strategy that works.
                  </p>
                </Col>
                <Col sm={12} lg={6}>
                  <div className="about-us-section__icon">
                    <IoMdLaptop
                      size={50}
                      className="list--services__item__icon"
                    />
                  </div>
                  <h5 className="subtitle">
                    Website Development &amp; Maintenance
                  </h5>
                  <p>
                    Taking a brand-centric approach, we build websites around
                    what your customers need and what your brand wants to say.
                  </p>
                </Col>
                <Col sm={12} lg={6}>
                  <div className="small-banner">
                    <h5 className="small-banner__text">
                      We deliver perpetual excellence across our wide range of
                      products and services
                    </h5>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="cms-logos-section">
            <Container>
              <Row>
                <div className="company-logo mb-5">
                  <a href="https://www.sitefinity.com/" target="_blank">
                    <img src={sitefinity} alt="sitefinity" />
                  </a>
                </div>
                <div className="company-logo mb-5">
                  <a href="https://www.sitecore.com/" target="_blank">
                    <img src={sitecore} alt="sitecore" />
                  </a>
                </div>
                <div className="company-logo mb-5">
                  <div>
                    <a
                      href="https://products.office.com/en-AU/SharePoint/"
                      target="_blank"
                    >
                      <img src={sharepoint} alt="sharepoint" />
                    </a>
                  </div>
                </div>
                <div className="company-logo mb-5">
                  <div>
                    <a href="https://umbraco.com/" target="_blank">
                      <img src={umbraco} alt="umbraco" />
                    </a>
                  </div>
                </div>
                <div className="company-logo mb-5">
                  <div>
                    <a href="https://www.drupal.org/" target="_blank">
                      <img src={drupal} alt="drupal" />
                    </a>
                  </div>
                </div>
                <div className="company-logo mb-5">
                  <div>
                    <a href="https://azure.microsoft.com/" target="_blank">
                      <img src={azure} alt="azure" />
                    </a>
                  </div>
                </div>
                <div className="company-logo mb-5">
                  <div>
                    <a href="https://angular.io/" target="_blank">
                      <img src={angular} alt="angular" />
                    </a>
                  </div>
                </div>
                <div className="company-logo mb-5">
                  <div>
                    <a href="https://dotnet.microsoft.com/" target="_blank">
                      <img src={dotnet} alt="dotnet" />
                    </a>
                  </div>
                </div>
              </Row>
            </Container>
          </section>
        </div>
      </LayoutAboutUs>
    </>
  );
}

export default AboutUs;
